import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";


export default function TermsConditons(){
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>T&Cs</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li>T&Cs</li>
                            </ul>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}
