import React from "react";
import "./whychoose.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Practice from "../../Assets/practice.jpg";
import Sreekumar from "../../Assets/Sreekumar.jpg";
import Sneha from "../../Assets/sneha.jpg";
import Technologies from "../../Assets/technologies.jpg";
import DrMeenu from "../../Assets/Drmeenu.jpg";
import DrAastha from "../../Assets/Draastha.jpg";

export default function WhyChoose() {
    return (
        <React.Fragment>
            <Header />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadhead">
                        <h1>Why Choose Us</h1>
                    </div>
                    <div className="breadcrumblist">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Why Choose Us</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="practice">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6">
                            <div className="practiceleft">
                                <div className="practicehed">
                                    <h2>Why Choose Us for Your Dental Care?</h2>
                                </div>
                                <div className="practicecontent">
                                    <h3>Our sole aim is to provide the highest quality dental care in an environment that prioritizes your health, comfort, and satisfaction. Here are some reasons why patients choose us:</h3>
                                    <ol>
                                        <li><b>Excellent Hygiene & Cleanliness:</b> Your safety is our top priority. We adhere to very stringent protocols of sterilization and infection control, ensuring a clean, hygienic environment for every visit.</li>
                                        <li><b>Highly Skilled Expert Team:</b> Our team of dentists and specialists brings years of experience, advanced training, and a passion for excellence in every procedure, be it a routine checkup or a complex treatment, you’re in truly expert hands.</li>
                                        <li><b>Research-based Methodology:</b> Our approach is deep-rooted in the latest research and best practices to provide you with the most effective and reliable care.</li>
                                        <li><b>Advanced Techniques, Technology, and Operating Systems:</b> We value your time. Our streamlined scheduling, treatment planning, and patient communication systems ensure that your visits are smooth, efficient, and stress-free.</li>
                                        <li><b>5-Star Patient Reviews:</b> We’re proud of the happy feedback we’ve received for our exceptional care, personalized service, and comfortable patient experience.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="practiceimage">
                                <img src={Practice} alt="Dental practice environment" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12">
                            <div className="practicecontent">
                                <p>Choose us for advanced and expert dental care that combines modern knowledge and innovation with a patient-centered approach. Let us help you achieve a healthy and beautiful smile that you truly deserve. Give us a <Link to="tel:+919004366342">call</Link> to know more.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="meetourteam">
                <div className="container">
                    <div className="meetteamhead">
                        <h2>Meet our specialists</h2>
                        <p>With years of expertise and dedication, our team of specialists is committed to delivering personalized care tailored to your unique needs.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="meetteamimg">
                                <img src={Sneha} alt="Dr Sneha Bharuka Panikar" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="meetteamcontent">
                                <h3>Dr Sneha Bharuka Panikar</h3>
                                <small>Practice Owner</small>
                                <p>Dr Sneha did her post-graduation in Paediatric and Preventive Dentistry...</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="meetteamimg">
                                <img src={Sreekumar} alt="Dr Sreekumar Panikar" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="meetteamcontent">
                                <h3>Dr Sreekumar Panikar</h3>
                                <small>Principal Dentist</small>
                                <p>Sree started his journey in the private practice of dentistry...</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="meetteamimg">
                                <img src={DrMeenu} alt="Dr Meenu Dinesh" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="meetteamcontent">
                                <h3>Dr Meenu Dinesh</h3>
                                <small>Orthodontist</small>
                                <p>Dr Meenu is an orthodontist by specialty training...</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="meetteamimg">
                                <img src={DrAastha} alt="Dr Aastha Dureja" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="meetteamcontent">
                                <h3>Dr Aastha Dureja</h3>
                                <small>Endodontist</small>
                                <p>Dr Aastha is an endodontist with Panikar’s Centre...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="technologies">
                <div className="container">
                    <div className="technologieshead">
                        <h2>Latest Technology</h2>
                        <p>Thanks to major technological advancements, dentistry allows treating the most complex cases with less time and more efficiency.</p>
                    </div>
                </div>
                <div className="container mt-45">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="technologiesimg">
                                <img src={Technologies} alt="Modern dental technology" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="technologiescontent">
                                <h3>The Future of Dentistry is Digital:</h3>
                                <p>Dentists today utilize software to capture insights in clinical decision-making...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}