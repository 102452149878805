import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { FaBars, FaAngleUp, FaPhone, FaEnvelopeOpenText } from "react-icons/fa6";
import Mainlogo from "../../Assets/mainlogo.png"
import { FaTimes } from "react-icons/fa";

export default function Header(){
    useEffect(() => {
        const header = document.querySelector('.mainheaderarea');
        const sticky = header.offsetTop;
        const handleScroll = () => {
            if (window.scrollY > sticky) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    /*---------- Mobile Menu ----------*/
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const toggleSidebar = () => {
        setIsSidebarOpen(prev => {
            const newState = !prev;
            document.body.classList.toggle('no-scroll1', newState);
            return newState;
        });
    };
    const toggleSidebarClose = () => {
        setIsSidebarOpen(prev => {
            document.body.classList.remove('no-scroll1');
            return false;
        });
    };
    const toggleDropdown = (dropdown) => {
        setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
    };
    return(
        <React.Fragment>
            <header className="mainheaderarea">
                <section className="headertop">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                                <div className="headertopleft">
                                    <p>Leading Dentist in Sanpada, Navi Mumbai​</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                                <div className="headertopright">
                                    <ul>
                                        <li><FaPhone/> <Link to="tel:+919004366342">+919004366342</Link></li>
                                        <li><FaEnvelopeOpenText/> <Link to="mailto:panikarsdentistry@gmail.com">panikarsdentistry@gmail.com</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="headerarea">
                        <div className="headerlogo">
                            <Link to="/"><img src={Mainlogo} alt="Main Logo"/></Link>
                        </div>
                        <div className="headermenu desktopmenu">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li className="haschild"><Link to="#">Treatments</Link> <FaAngleUp/>
                                    <ul className="has-submenu">
                                        <li><Link to="/dental-implants/">Dental Implants</Link></li>
                                        <li><Link to="/smile-enhancement/">Smile Enhancement</Link></li>
                                        <li><Link to="/full-mouth-rehabilitation/">Full Mouth Rehabilitation</Link></li>
                                        <li><Link to="/teeth-alignment/">Teeth Alignment</Link></li>
                                        <li><Link to="/children-dentistry/">Children Dentistry</Link></li>
                                        <li><Link to="/general-dentistry/">General Dentistry</Link></li>
                                        <li><Link to="/nervous-patients/">Nervous Patients</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/smile-gallery/">Smile Gallery</Link></li>
                                <li><Link to="/why-choose-us/">Why Choose Us</Link></li>
                                <li className="booknowbtns"><Link to="/contact-us/">Book Now</Link></li>
                                <li className="menutoggle haschild"><FaBars/>
                                    <ul className="has-submenu">
                                        <li><Link to="/contact-us/">Contact Us</Link></li>
                                        <li><Link to="/faqs/">FAQ’s</Link></li>
                                        <li><Link to="/blogs/">Blogs</Link></li>
                                        <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                                        <li><Link to="/location/">Location</Link></li>
                                        <li><Link to="/terms-conditions/">T&Cs</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="mobileheadertoggle">
                            <button type="button" onClick={toggleSidebar}><FaBars/></button>
                        </div>
                    </div>
                </div>
            </header>
            <div className={`menuleftslide sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
                {isSidebarOpen && (
                    <div className="mobilemenuarea">
                        <button class="th-menu-toggle close_menu" onClick={toggleSidebarClose}><FaTimes/></button>
                        <div className="mobilemenulogoarea">
                            <Link to="/"><img src={Mainlogo} alt="Main Logo" /></Link>
                        </div>
                        <div className="mobilemenuitems">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li className={`has-submenu ${activeDropdown === 'treatments' ? 'active' : ''}`}>
                                    <Link to="#" onClick={() => toggleDropdown('treatments')}>Treatments <span class="th-mean-expand"></span></Link>
                                    {activeDropdown === 'treatments' && (
                                        <ul className="menusub-menu">
                                            <li><Link to="/dental-implants/">Dental Implants</Link></li>
                                            <li><Link to="/smile-enhancement/">Smile Enhancement</Link></li>
                                            <li><Link to="/full-mouth-rehabilitation/">Full Mouth Rehabilitation</Link></li>
                                            <li><Link to="/teeth-alignment/">Teeth Alignment</Link></li>
                                            <li><Link to="/children-dentistry/">Children Dentistry</Link></li>
                                            <li><Link to="/general-dentistry/">General Dentistry</Link></li>
                                            <li><Link to="/nervous-patients/">Nervous Patients</Link></li>
                                        </ul>
                                    )}
                                </li>
                                <li><Link to="/smile-gallery/">Smile Gallery</Link></li>
                                <li><Link to="/why-choose-us/">Why Choose Us</Link></li>
                                <li><Link to="/contact-us/">Contact Us</Link></li>
                                <li><Link to="/faqs/">FAQ’s</Link></li>
                                <li><Link to="/blogs/">Blogs</Link></li>
                                <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                                <li><Link to="/location/">Location</Link></li>
                                <li><Link to="/terms-conditions/">T&Cs</Link></li>
                                <li className="booknowbtns"><Link to="/contact-us/">Book Now</Link></li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}
