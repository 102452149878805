import React from "react";
import { Link } from "react-router-dom";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import WhatDentist from "../../../Assets/what.png";
import DentistType from "../../../Assets/dentisttype.jpg";
import Accordion from 'react-bootstrap/Accordion';


export default function SmileEnhancement(){
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>General Dentistry</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li>General Dentistry</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="whatdentist">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-5">
                                <div className="whatdentistimg">
                                    <img src={WhatDentist} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="whatdentistcontent">
                                    <h2>what is Dental Implants?</h2>
                                    <p>Dental implants are a permanent and effective solution for replacing missing teeth, providing the look, feel, and function of natural teeth. We offer a range of dental implant options to suit every patient's unique needs, ensuring a comfortable and successful treatment experience. Whether you're missing a single tooth, multiple teeth, or need a full mouth restoration, our expert team is here to help you restore your smile and confidence.</p>
                                    <div className="whatdentistbtn">
                                        <Link to="#">Book an Appointment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dentisttype">
                    <div className="container">
                        <div className="dentisthead">
                            <h2>Types of Dental Implants we offer</h2>
                            <p>Custom Dental Implants for every smile - single, multiple, or full mouth solutions tailored to restore your confidence and oral health.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="dentisttypeimg">
                                    <img src={DentistType} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="dentisttypecontent">
                                    <h3>Single Tooth Implant</h3>
                                    <p>Single tooth implants involve placing one implant and a crown to replace a single missing tooth. This solution not only fills the gap but also preserves the health of adjacent teeth and prevents bone loss.</p>
                                    <p><b>Benefits:</b> Natural appearance, long-lasting, prevents bone deterioration, and no impact on surrounding teeth.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="dentisttypecontent">
                                    <h3>Multiple Tooth Implants</h3>
                                    <p>Single tooth implants involve placing one implant and a crown to replace a single missing tooth. This solution not only fills the gap but also preserves the health of adjacent teeth and prevents bone loss.</p>
                                    <p><b>Benefits:</b> Natural appearance, long-lasting, prevents bone deterioration, and no impact on surrounding teeth.</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="dentisttypeimg">
                                    <img src={DentistType} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="dentisttypeimg">
                                    <img src={DentistType} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="dentisttypecontent">
                                    <h3>Full Mouth Implants</h3>
                                    <p>Single tooth implants involve placing one implant and a crown to replace a single missing tooth. This solution not only fills the gap but also preserves the health of adjacent teeth and prevents bone loss.</p>
                                    <p><b>Benefits:</b> Natural appearance, long-lasting, prevents bone deterioration, and no impact on surrounding teeth.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faqs">
                    <div className="container">
                        <div className="faqsheader">
                            <h2>FAQ’s</h2>
                            <p>Everything you need to know</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are dental implants?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges,
                                        mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Who can get dental implants?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges,
                                        mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Is the procedure painful?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges, mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How long do dental implants last?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges, mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How do I care for dental implants?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges, mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}
