import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./Pages/Home";
import DentalImplants from "./Pages/Treatments/Dentalimplants";
import WhyChoose from "./Pages/WhyChoose";
import SmileGallery from "./Pages/SmileGallery"
import NotFound from "./Pages/Error"
import Faqs from "./Pages/Faqs"
import Blogs from "./Pages/Blogs"
import Contactus from "./Pages/Contactus"
import SmileEnhancement from "./Pages/Treatments/SmileEnhancement"
import FullMouthRehabilitation from "./Pages/Treatments/FullMouthRehabilitation"
import TeethAlignment from "./Pages/Treatments/TeethAlignment"
import ChildrenDentistry from "./Pages/Treatments/ChildrenDentistry"
import GeneralDentistry from "./Pages/Treatments/GeneralDentistry"
import NervousPatients from "./Pages/Treatments/NervousPatients"
import PrivacyPolicy from "./Pages/PrivacyPolicy"
import Termsconditions from "./Pages/TermsConditions"

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dental-implants" element={<DentalImplants />} />
          <Route path="/why-choose-us" element={<WhyChoose />} />
          <Route path="/smile-gallery" element={<SmileGallery />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/smile-enhancement" element={<SmileEnhancement />} />
          <Route path="/full-mouth-rehabilitation" element={<FullMouthRehabilitation />} />
          <Route path="/teeth-alignment" element={<TeethAlignment />} />
          <Route path="/children-dentistry" element={<ChildrenDentistry />} />
          <Route path="/general-dentistry" element={<GeneralDentistry />} />
          <Route path="/nervous-patients" element={<NervousPatients />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<Termsconditions />} />
          
          {/*---------Error Page----------*/}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;