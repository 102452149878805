import React from "react";
import { Link } from "react-router-dom";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import Fmr from "../../../Assets/fmr.png"


const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
};


export default function SmileEnhancement(){
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Full Mouth Rehabilitation</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li>Full Mouth Rehabilitation</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="whatdentist">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-5">
                                <div className="whatdentistimg">
                                    <img src={Fmr} alt="Full Mouth Rehabilitation" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="whatdentistcontent">
                                    <h2>What is Full Mouth Rehabilitation?</h2>
                                    <p>Full mouth rehabilitation, also known as full mouth reconstruction/renovation, involves a series of dental treatments that work together to rebuild or replace all of your teeth. This customized treatment plan is designed to address multiple issues such as tooth decay, gum disease, broken teeth, and bite misalignment, ensuring that your mouth is fully restored both functionally and aesthetically.</p>
                                    <div className="whatdentistbtn">
                                        <Link to="#" onClick={() => scrollToSection('appointment')}>Book an Appointment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dentisttype">
                    <div className="container">
                        <div className="dentisthead">
                            <h2>Full Mouth Rehabilitation</h2>
                            <p>Full Mouth Rehabilitation is a comprehensive approach to restoring the health, function, and appearance of your entire mouth. Whether due to tooth decay, trauma, wear and tear, or other dental conditions, our expert team is committed to delivering personalized care to help you achieve a healthy, radiant smile.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Who Needs Full Mouth Rehabilitation?</h3>
                                    <p>Full mouth rehabilitation may be necessary if you experience:</p>
                                    <ul>
                                        <li>Severe tooth wear from grinding or acid erosion.</li>
                                        <li>Multiple missing teeth due to decay, injury, or disease.</li>
                                        <li>Worn or broken teeth impacting your bite, ability to chew, pain or sensitivity.</li>
                                        <li>Chronic jaw pain or TMJ disorders caused by misaligned teeth or bite.</li>
                                        <li>Periodontal (gum) disease affecting the stability of your teeth.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Our Comprehensive Approach</h3>
                                    <p>We understand that every patient is unique. Our full mouth rehabilitation process begins with an in-depth consultation and thorough examination to assess the existing condition of your teeth, gums, and bite. Based on your specific needs, we create a properly tailored treatment plan which may include:</p>
                                    <ul>
                                        <li>Dental Implants – To replace missing teeth and restore function.</li>
                                        <li>Crowns and Bridges – To rebuild damaged teeth and enhance appearance.</li>
                                        <li>Porcelain Veneers – To improve the look of worn or discolored teeth.</li>
                                        <li>Orthodontics – To correct bite alignment and improve jaw function.</li>
                                        <li>Root Canal Therapy – To treat infections and preserve natural teeth.</li>
                                        <li>Teeth Whitening – To give your smile a fresh, bright appearance.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Why Choose Full Mouth Rehabilitation?</h3>
                                    <p>Opting for full mouth rehabilitation offers several life-changing benefits:</p>
                                    <ul>
                                        <li>Improved oral health by treating underlying conditions like decay and gum disease, thereby improving overall health.</li>
                                        <li>Enhanced function, making it easier to chew, speak, smile, laugh and enjoy your favorite foods.</li>
                                        <li>A beautiful smile, boosting your confidence, self-esteem and happiness quotient.</li>
                                        <li>Relief from discomfort caused by jaw pain or bite problems and positively impacting quality of life.</li>
                                        <li>Long-term stability by addressing all aspects of your oral health in one cohesive plan with maintainable results.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Our Commitment to You</h3>
                                    <p>At Panikar's Dentistry, your comfort and satisfaction are our top priorities. We use state-of-the-art digital technology and high-quality materials to ensure that your full mouth rehabilitation is as smooth, efficient and effective as possible. Our experienced dental team will guide you through every step of the process, from initial diagnosis to the final result, ensuring you feel confident and well cared for throughout your treatment journey.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Schedule A Consultation</h3>
                                    <p>If you’re ready to transform yourself and regain full oral health and beauty, <Link to="https://wa.me/+919004366342/" target="_blank">contact us</Link> today to schedule your full mouth rehabilitation consultation. Let us help you achieve the smile you deserve with a personalized, comprehensive treatment plan designed just for you.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}
